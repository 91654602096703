<div class="modal-content">
    <div class="modal-header">
      <div>
        <h4>O‘qituvchi bandligini tekshirish</h4>
      </div>
      <div>
        <button type="button" class="btn btn-outline-danger" (click)="close()">Bekor qilish</button>
      </div>
    </div>
    <div class="modal-body" [class.found]="calendarVisible">
      <div id="eventTooltip" style="display: none; position: absolute; background-color: #fff; padding: 5px; border: 1px solid #ccc;"></div>
      <div class="modal-body_item mtop10" >
          <label>O‘qituvchi</label>
          <div class="modal-body_item_sec">
              <div class="modal-body_item_sec">
                  <ng-autocomplete
                      [data]="timeTableTeachers"
                      class="form-control-inner"
                      [searchKeyword]="keyword"
                      (selected)='selectTeacherEvent($event)'
                      (inputCleared) = 'teacherClearedEvent($event)'
                      [itemTemplate]="itemTemplate"
                      id="teacher_yu"
                      #teacher_yu
                  >
                  </ng-autocomplete>
                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>
      
                <ng-template #notFoundTemplateRoom let-notFound>
                     <div class="red">
                      topilmadi! 
                   </div>
                </ng-template>
              </div>
          </div>      
      </div> 
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-danger" (click)="close()">Bekor qilish</button>
    </div> -->
</div>