
export const environment= {
  //Eslatma TSNQB 
  otm: 'uzdjtsuff',
  main_name: 'TSNQB',
  production: true,
  http_auth: 'asnfa@#fasdffsedfnnsd',
  otm_name: 'Ta\'lim sifatini nazorat qilish bo\'limi',
  firebaseConfig: { // fdu
    apiKey: "AIzaSyBs2hR0qibVtXI6NHKsD7tTZo3leTTzrVo",
    authDomain: "pvorey-8f8a3.firebaseapp.com",
    projectId: "pvorey-8f8a3",
    storageBucket: "pvorey-8f8a3.appspot.com",
    messagingSenderId: "110763375175",
    appId: "1:110763375175:web:b0ec673a4b3c38e5bc0f62",
    measurementId: "G-3EBYBCYC70"
  },
  http: {
    userRegister:{
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/registration'
    },
    recoverPass:{
      path: 'https://uzdjtsuff.tsnqb.uz/api/mailing/send-mail'
    },
    allRoles: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/login'
    },
    removeRole: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/login'
    },
    setRole: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/login'
    },
    removeTeacher: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/login'
    },
    allUsers: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/users/all'
    },
    updateUser: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/login'
    },
    deleteUser: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/login'
    },
    login: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/login'
    },
    autologin: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/autologin'
    },
    get: {// https://tsnqb.ferpi.uz/db_pvorey_test //https://2shahar.uz/tsnqb/get_data.php
       path: 'https://uzdjtsuff.tsnqb.uz/db/2023/get_data.php',
       file: 'pvorey.txt'
    },
    delete_only_inform_file: {
      path: "https://uzdjtsuff.tsnqb.uz/db/2023/delete_only_inform_file.php"
    },
    getFieldsInform: {
       path: 'https://uzdjtsuff.tsnqb.uz/db/2023/get_fields_inform.php'
    },
    setInc: {
       path: 'https://uzdjtsuff.tsnqb.uz/db/2023/set_data_inc.php',
       file: 'pvorey.txt'
    },
    del: {
       path: 'https://uzdjtsuff.tsnqb.uz/db/2023/del_data.php',
       file: 'pvorey.txt'
    },
    setIncDelUpd: {
       path: 'https://uzdjtsuff.tsnqb.uz/db/2023/set_inc_del_upd.php',
       file: 'pvorey.txt'
    },
    remDocDownGrade: {
       path: 'https://uzdjtsuff.tsnqb.uz/db/2023/rem_doc_with_down_grade.php',
    },
    googleScholarCited: {
       path: 'https://uzdjtsuff.tsnqb.uz/db/2023/google_scholar.php',
    },
    googleScholarCitedAll: {
       path: 'https://uzdjtsuff.tsnqb.uz/db_ferpi/2023/google_scholar_all.php',
    },
    setNewPvo: {
       path: 'https://uzdjtsuff.tsnqb.uz/db/2023/set_new_pvo.php',
    },
    removePvo: {
       path: 'https://uzdjtsuff.tsnqb.uz/db/2023/remove_pvo.php',
    },
    controlIndexStatus: {
       path: 'https://uzdjtsuff.tsnqb.uz/db/2023/control_index_status.php',
    },
    createAnnounce: {
       path: 'https://uzdjtsuff.tsnqb.uz/db/2023/create_announce.php',
    },
    getAnnounces: {
       path: 'https://uzdjtsuff.tsnqb.uz/db/2023/get_announces.php',
    },
    removeAnnounce: {
       path: 'https://uzdjtsuff.tsnqb.uz/db/2023/remove_announce.php',
    },
    editUserAccaunt: {
       path: 'https://uzdjtsuff.tsnqb.uz/db/2023/edit_user_account.php',
    },
    createIndex: {
       path: 'https://uzdjtsuff.tsnqb.uz/db/2023/create_index.php',
    }
  },
  tizim: [
    {
      fakultet: 'Sport turlari',
      kafedralar: [
        'Yakkakurash sport turlari nazaryasi va uslubyati',
        'Sport o‘yinlari nazariyasi va uslubyati',
        'Ko‘pkurash sport turlari nazariyasi va uslubyati',
        'Jismoniy tarbiya, sport nazariyasi va uslubiyati',
      ]
    },
  ],
  grastud: 300,
  fakultet: ['Sport turlari'],
  kafedra: [
    'Yakkakurash sport turlari nazaryasi va uslubyati',
    'Sport o‘yinlari nazariyasi va uslubyati',
    'Ko‘pkurash sport turlari nazariyasi va uslubyati',
    'Jismoniy tarbiya, sport nazariyasi va uslubiyati',
  ]
};